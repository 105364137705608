
import { Options, Vue } from 'vue-class-component';
import { Prop } from 'vue-property-decorator';

@Options({
  components: {},
})

export default class SlideLarge extends Vue {
  @Prop() modelValue!: boolean;
  @Prop() number!: number;

  caseName = ['Компания «Роснефть»', 'Интернет-магазин «Дядько»', 'Интернет-магазин «Pigeon Silver»', 'Студия флористического дизайна Феерия']
  caseDescription = ['Компания занимается добычей и реализацией нефти, газа и продуктов их переработки', 'Компания занимается поставками инструментов и запчастей ', 'Компания занимается продажей ювелирных украшений из радированного серебра', 'Компания занимается продажей и доставкой цветов в Крыму']
  caseProblem = ['Бухгалтер компании работал удаленно, а реализация продукции и основных средств происходила на широкой географии. Чеки необходимо было выбивать и выдавать каждому покупателю', 'Многие из их покупателей являются ИП или подотчетными лицами и имеют строгую отчетность, поэтому необходимо было решить вопрос с автоматической отправкой чеков, после оплаты заказа', 'Большая доля продаж приходится на онлайн-заказы в соц.сетях, поэтому необходимо было автоматизировать процесс онлайн-оплаты', 'Начали появляться проблемы с онлайн-платежами. После введение в бизнес услуги доставки курьером, собственник понял, что пора автоматизировать продажи']
  caseResolve = ['Мы настроили автоматическую онлайн-отправку чеков и систематизировали удаленную отчетность', 'Настроили облачную онлайн-кассу под эквайринг и банк клиента, практически без его участия', 'Настроили VDCloud так, чтобы платформа автоматически деактивировала код маркировки после покупки, а также автоматическую отправку чеков клиентам по SMS', 'Мы подобрали местный сервис, который подключили клиенту. А также обучили команду эксплуатации облачной кассы']
  caseResult = ['Каждый покупатель получал чек сразу после покупки на электронную почту или другие сервисы', 'Подключив «VDCloud», собственник решил проблему с отправкой чеков. Автоматизированная отчетность позволила сэкономить большое количество времени', 'Подключив «VDCloud», клиент закрыл сразу две проблемы', 'Процесс учета товара стал полностью автоматизирован']
}
